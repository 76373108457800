
import { Store } from "backend";
import clsx from "clsx";
import * as React from "react";

import defaultImage from "../../assets/shamiko.jpg";
import { LoadingSpinner } from "./utils/LoadingSpinner";
import _styles from "./TeamImage-styles.module.scss";


const styles = _styles;

export function TeamImage(props: {
	team: Store.ContestTeam;
	className?: string;
	imageClassName?: string;
}) {
	if (props.team == null) {
		return null;
	}

	return <div
		className={clsx(styles.container, props.className)}
	>
		{props.team.image === undefined
			? <LoadingSpinner />
			: <img src={props.team.image ?? defaultImage} className={clsx(props.imageClassName, styles.image)} />
		}
	</div>;
}
