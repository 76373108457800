
import * as React from "react";
import Container from "react-bootstrap/Container";
import { BsChevronCompactDown,BsChevronCompactUp } from "react-icons/bs";
import _styles from "./ArrowToggle-styles.module.scss";


const styles = _styles;

export const ArrowToggle: React.FC<{
	pointUp?: boolean
}> = ({pointUp}) => {
	return <Container className={styles.arrow}>
		{pointUp
			? <BsChevronCompactUp color="white" size="30px" />
			: <BsChevronCompactDown color="white" size="30px" />
		}
	</Container>;
};
